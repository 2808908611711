body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* * * * * * * * Non-Default CSS code below * * * * * * * * */

/* * * * * Fonts * * * * */

@font-face {
  font-family: Ubuntu-Regular;
  src: url(./Assets/Fonts/Ubuntu-Regular.ttf);
}

@font-face {
  font-family: Ubuntu-Bold;
  src: url(./Assets/Fonts/Ubuntu-Bold.ttf);
}

/* * * * * Elements * * * * */

.home-header{
  background-image: linear-gradient(to top, #0a0033, #000000);
  background-size: fill;
  background-position: 80% 50%;
}

.image-of-richard{
  height: 20em;
  border-radius: 1em;
  box-shadow: 5px 5px 5px #000000;
}

.header-row{
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  padding: 1em 0em 1em 0em;
}

.projects-and-info-row{
  display: flex;
  flex-direction: column;
}

.name-and-subtitle{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
}

.name{
  font-family: Ubuntu-Bold;
  font-size: 2em;
  text-align: center;
}

.subtitle{
  font-family: Ubuntu-Regular;
  font-size: 1.2em;
  text-align: center;
}

.page-body{
  background-image: linear-gradient(to bottom, #45bbdc, #158dae, #187994);
  /* border-top: solid #a6f3ff 2px; */
  color: #000;
}

.resume-page-body{
  background-image: linear-gradient(to bottom, #45bbdc, #158dae, #187994);
  color: #000;
}

.pkmn-app-card{
  display: flex;
  flex-direction: column;
  background-color: #bbf6ff;
  width: 15em;
  padding: 1em;
  border-radius: 1em;
  color: #000;
  font-family: Ubuntu-Regular;
  border: 1px solid #464646;
}

.project-card-thumbnail{
  border-radius: 1em 1em 1em 1em;
}

.project-card-title{
  font-family: Ubuntu-Bold;
}

.heading-text{
  font-family: Ubuntu-Bold;
  color: #000000;
}

.resume-view-heading{
  font-family: Ubuntu-Bold;
  color: #000000;
  text-align: center;
  padding-top: 1em;
  padding-bottom: 1em;
}

.skills-heading{
  font-family: Ubuntu-Bold;
  color: #FFFFFF;
  text-align: center;
}

.paragraph-text{
  font-family: Ubuntu-Regular;
}

.hero-image-right-half{
  display: flex;
  flex-direction: column;
}

.thumbnail-image-div{
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumbnail-image{
  width: 19em;
  border-radius: 1em 1em 0em 0em;
}

.skills-row{
  display: flex;
  flex-direction: row;

}

.skill-icon{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.no-dec{
  display: flex;
  text-decoration: none;
  max-width: fit-content;
  min-width: fit-content;
}

.project-row{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.list-no-dec{
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.icon-size{
  height: 1.5em;
  width: auto;
}

.resume-style{
  display: flex;
  flex-direction: column;
  padding: 2em;
  font-family: Ubuntu-Regular;
  color: #000000;
}

.resume-headings{
  font-family: Ubuntu-Bold;
}

.resume-background{
  padding-top: 4.5em;
  padding-bottom: 2em;
  background-color: #d2d2d2;
  box-shadow: 10px 10px 10px rgb(46, 80, 109);

}



/* * * * * * * * Buttons * * * * * * * * */

.pkmn-app-btn{
  display: flex;
  background-color: #27016f;
  color: #FFFFFF;
  border: none;
  margin: 0.5em 0em 0.5em 0em;
  border-radius: 0.5em;
  padding: 6.8px 12px;
  text-align: center;
  text-decoration: none;
  justify-content: center;
  font-family: Ubuntu-Regular;

}

.pkmn-app-btn:hover{
  display: flex;
  background-color: #350194;
  color: #FFFFFF;
  border: none;
  margin: 0.5em 0em 0.5em 0em;
  border-radius: 0.5em;
  padding: 6.8px 12px;
  text-align: center;
  text-decoration: none;
  justify-content: center;
  font-family: Ubuntu-Regular;
}

.not-working:hover{
  cursor: not-allowed;
}

.resume-page{
  display: flex;
  flex-direction: column;
}



/* * * * * * * * * * * * * * Media Queries * * * * * * * * * * * *  */

@media (max-width: 769px) {
  .header-row{
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }

  .image-of-richard{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }

  .project-row{
    display: flex;
    flex-direction: column;
    width: auto;
  }

  .pkmn-app-card{
    display: flex;
    flex-direction: column;
    margin: auto;
    background-color: #bbf6ff;
    width: auto;
    /* padding: 1em; */
    border-radius: 1em;
    color: #000000;
    font-family: Ubuntu-Regular;
    border: 1px solid #464646;
  }

  .thumbnail-image-div{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .thumbnail-image{
    width: auto;
    border-radius: 1em 1em 0em 0em;
  }
}

.navbar{
  font-family: Ubuntu-Regular;
}

.custom-nav{
  display: flex;
  background-color: #000000;
  flex-direction: row;
  /* justify-content: space-around; */
  align-items: center;
  align-content: center;
  
  font-family: Ubuntu-Regular;
}

.custom-nav-item{
  margin: auto;
  
  text-decoration: none;
  color: #FFFFFF;
  float: left;
  padding: 1.5em 1em 1.5em 1em;
  transition-duration: 0.5s;
}

.custom-nav-item:hover{
  margin: auto;
  background-color: #45bbdc;
  text-decoration: none;
  color: #001133;
  float: left;
  padding: 1.5em 1em 1.5em 1em;
  cursor: pointer;
  transition-duration: 0.5s;
}

.site-title{
  font-family: Ubuntu-Bold;
  transition-duration: 0.5s;
  padding: none;
}
.site-title:hover{
  font-family: Ubuntu-Bold;
  transition-duration: 0.5s;
  padding: none;
  background-color:#000000;
  color: #FFFFFF;
  cursor: default;
}